import React from "react";

type Props = {
  className: string;
  clickThrough: boolean;
};

const Overlay = ({ className = "", clickThrough = false }: Props) => {
  return (
    <div
      className={`overlay ${className}`}
      style={{ display: clickThrough ? "none" : "block" }}
    ></div>
  );
};

export default Overlay;
