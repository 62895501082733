import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  totalSeconds: number;
};

const Timer = ({ totalSeconds }: Props) => {
  const seconds = totalSeconds % 60;
  const minutes = Math.floor((totalSeconds / 60) % 60);
  const hours = Math.floor((totalSeconds / (60 * 60)) % 24);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));

  const handleClick = (precision: string, e: React.MouseEvent) => {
    e.stopPropagation();
    copyToClipboard(precision);
  };

  const copyToClipboard = async (precision: string) => {
    toast(`${precision} copied to clipboard`);
    switch (precision) {
      case "Days":
        await navigator.clipboard.writeText(`${days} days`);
        break;
      case "Hours":
        await navigator.clipboard.writeText(`${days} days, ${hours} hours`);
        break;
      case "Minutes":
        await navigator.clipboard.writeText(
          `${days} days, ${hours} hours, ${minutes} minutes`
        );
        break;
      case "Seconds":
      default:
        await navigator.clipboard.writeText(
          `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
        );
        break;
    }
  };

  return (
    <div className="timer" role="timer">
      <div className="col-4">
        <div className="box" onClick={(e) => handleClick("Days", e)}>
          <p id="day">{days < 10 ? "0" + days : days}</p>
          <span className="text">{days === 1 ? "Day" : "Days"}</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box" onClick={(e) => handleClick("Hours", e)}>
          <p id="hour">{hours < 10 ? "0" + hours : hours}</p>
          <span className="text">{hours === 1 ? "Hour" : "Hours"}</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box" onClick={(e) => handleClick("Minutes", e)}>
          <p id="minute">{minutes < 10 ? "0" + minutes : minutes}</p>
          <span className="text">{minutes === 1 ? "Minute" : "Minutes"}</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box last" onClick={(e) => handleClick("Seconds", e)}>
          <p id="second">{seconds < 10 ? "0" + seconds : seconds}</p>
          <span className="text">{seconds === 1 ? "Second" : "Seconds"}</span>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="dark"
      />
    </div>
  );
};

export default Timer;
