import React from "react";

type Props = {
  connections: number;
};

const ConnectionCounter = ({ connections }: Props) => {
  return (
    <div className="connectionCounter">
      <span className="text">{connections} Connections</span>
    </div>
  );
};

export default ConnectionCounter;
